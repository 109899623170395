import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Api from "../../services/api";
import { API_CONTRACTADDRESS } from "../../services/api-config";
import _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { sendTokens } from "../../utils/Util";
import { toast } from "react-toastify";
import "./modals.css";

let api;

class WithdrawModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      decAmount: "",
      buttonDisabled: true,
      show: false,
      timeHorizon: "",
      recieverAddress: "",
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
    };
  }
  openSuccess = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire("Withdraw Successfully Requested!", "", "success");
  };
  changecRecieverAddress = (event) => {
    this.setState({ recieverAddress: event.target.value });
  };

  sendTokens = async (
    listedTokenContractAddress,
    receivingEthAddress,
    tokens,
    transactionId
  ) => {
    await window.ethereum.enable();
    if (listedTokenContractAddress && receivingEthAddress && tokens) {
      const response = await sendTokens(
        listedTokenContractAddress,
        receivingEthAddress,
        tokens
      );
      this.props.withdrawOnCloseModal();
      this.setState({ decAmount: "", recieverAddress: "" });
      if (response && response.txHash) {
        await api.create("user/issuer/token/minted", {
          transactionId,
          txHash: response.txHash,
        });
      }
    } else {
      const erroMsg = !receivingEthAddress
        ? "User's wallet address is not whitelisted"
        : !tokens || tokens <= 0
        ? "Please enter a valid token."
        : "";
      this.props.withdrawOnCloseModal();
      toast.error(erroMsg);
      this.setState({ decAmount: "", recieverAddress: "" });
    }
  };

  showWithdraw = (address, amount) => {
    if (address && amount) {
      this.setState({ buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };
  getAllBalance = (value) => {
    this.setState({
      amount: value,
    });
  };
  clearTime = () => {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  };

  onChangeTimeHorizon = (time) => {
    this.setState({
      timeHorizon: time,
    });
  };
  onChangeAddress = (e) => {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.showWithdraw(this.state.address, this.state.amount);
      }
    );
  };
  onChangeAmount = (e) => {
    this.setState(
      {
        amount: e.target.value,
      },
      () => {
        this.showWithdraw(this.state.address, this.state.amount);
      }
    );
  };
  onChangedecAmount = (e) => {
    this.setState({
      decAmount: e.target.value,
    });
  };
  onSubmit = async () => {
    const { address, amount } = this.state;
    const { currency } = this.props;
    if (amount > 0) {
      const response = await api.create(
        currency === "VENLY" ? "miner/venly/withdrawBtc" : "miner/withdrawBtc",
        {
          address,
          amount,
        }
      );
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.props.withdrawOnCloseModal();
          this.openSuccess();
          this.setState(
            {
              address: "",
              amount: "",
            },
            () => {
              this.showWithdraw(this.state.address, this.state.amount);
            }
          );
        } else {
          this.props.withdrawOnCloseModal();
          toast.error(response.message);
          this.setState(
            {
              address: "",
              amount: "",
            },
            () => {
              this.showWithdraw(this.state.address, this.state.amount);
            }
          );
        }
      }
    } else {
      toast.error(`Invalid Amount!`);
    }
  };

  render() {
    const {
      withdrawModalOpen,
      withdrawOnCloseModal,
      btcBalance,
      currency,
      venlyBalance,
    } = this.props;

    const { buttonDisabled } = this.state;
    return (
      <Modal
        open={withdrawModalOpen}
        onClose={withdrawOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    {currency === "BTC" ? (
                      <div>
                        <h4
                          className=""
                          style={{ alignSelf: "flex-start !important" }}
                        >
                          Withdraw BTC
                        </h4>
                        <div className="form-group">
                          <label htmlFor="title">
                            Address<span className="required-field">*</span>
                          </label>
                          <input
                            className="form-control w-100"
                            id="address"
                            type="text"
                            placeholder="Enter Address"
                            value={this.state.address}
                            onChange={this.onChangeAddress}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="title">
                            Amount<span className="required-field">*</span>
                          </label>
                          <div className="amount">
                            <input
                              className="form-control"
                              id="amount"
                              type="number"
                              placeholder="Enter Amount"
                              value={this.state.amount}
                              onChange={this.onChangeAmount}
                            />
                            <span
                              onClick={() => {
                                this.getAllBalance(btcBalance);
                              }}
                            >
                              All
                            </span>
                          </div>
                        </div>

                        <div className="container-right">
                          <span className="gray">Amount : {btcBalance}</span>
                        </div>
                        <div className="d-flex justify-content-end mt-4 ">
                          <button
                            className="btn modal-button cancel-button mr-2"
                            type="button"
                            onClick={withdrawOnCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn modal-button update-button"
                            type="button"
                            disabled={buttonDisabled}
                            onClick={this.onSubmit}
                          >
                            {" "}
                            Withdraw{" "}
                            {this.state.paymentRequestButtonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )}
                          </button>
                        </div>
                      </div>
                    ) : currency === "VENLY" ? (
                      <div>
                        <h4
                          className=""
                          style={{ alignSelf: "flex-start !important" }}
                        >
                          Withdraw BTC
                        </h4>
                        <div className="form-group">
                          <label htmlFor="title">
                            Address<span className="required-field">*</span>
                          </label>
                          <input
                            className="form-control w-100"
                            id="address"
                            type="text"
                            placeholder="Enter Address"
                            value={this.state.address}
                            onChange={this.onChangeAddress}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="title">
                            Amount<span className="required-field">*</span>
                          </label>
                          <div className="amount">
                            <input
                              className="form-control"
                              id="amount"
                              type="number"
                              placeholder="Enter Amount"
                              value={this.state.amount}
                              onChange={this.onChangeAmount}
                            />
                            <span
                              onClick={() => {
                                this.getAllBalance(venlyBalance);
                              }}
                            >
                              All
                            </span>
                          </div>
                        </div>

                        <div className="container-right">
                          <span className="gray">Amount : {venlyBalance}</span>
                        </div>
                        <div className="d-flex justify-content-end mt-4 ">
                          <button
                            className="btn modal-button cancel-button mr-2"
                            type="button"
                            onClick={withdrawOnCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn modal-button update-button"
                            type="button"
                            disabled={buttonDisabled}
                            onClick={this.onSubmit}
                          >
                            {" "}
                            Withdraw{" "}
                            {this.state.paymentRequestButtonLoading && (
                              <i className="fa-spin fa fa-spinner text-white m-1" />
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {currency === "DEC" ? (
                      <div>
                        <h4
                          className=""
                          style={{ alignSelf: "flex-start !important" }}
                        >
                          Withdraw DEC
                        </h4>
                        <div className="form-group">
                          <div className="form-group">
                            <label htmlFor="title">
                              Reciever Address
                              <span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control w-100"
                              id="address"
                              type="text"
                              placeholder="Enter Address"
                              value={this.state.recieverAddress}
                              onChange={this.changecRecieverAddress}
                            />
                          </div>
                          <label htmlFor="title">
                            Amount<span className="required-field">*</span>
                          </label>
                          <div>
                            <input
                              className="form-control"
                              id="amount"
                              type="number"
                              placeholder="Enter Amount"
                              value={this.state.decAmount}
                              onChange={this.onChangedecAmount}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4 ">
                          <button
                            className="btn modal-button cancel-button mr-2"
                            type="button"
                            onClick={withdrawOnCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn modal-button update-button"
                            type="button"
                            onClick={() => {
                              this.sendTokens(
                                API_CONTRACTADDRESS,
                                this.state.recieverAddress,
                                this.state.decAmount
                              );
                            }}
                          >
                            {" "}
                            Withdraw{" "}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default WithdrawModal;
