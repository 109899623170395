import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as messageConstants from "../../utils/Messages";
import { niceNumberDecimalDisplay, pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class VenlyTranscationPage extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      buttonLoading: false,
      page: 1,
      sizePerPage: 20,
      filters: "",
      name: "",
      tableLoading: true,
      email: "",
      status: "",
      statusText: "",
      totalSize: "",
      ordersRenderFlag: false,
      ordersButtonLoading: false,
      ordersPage: 1,
      ordersSizePerPage: 10,
      ordersFilters: "",
      recordData: "",
      transactionData: [],
      renderFlag: true,
    };
    this.getRecords = this.getRecords.bind(this);
    this.copyDepositAddress = this.copyDepositAddress.bind(this);
    this.setTableData = this.setTableData.bind(this);
    this.getDataInBackground = this.getDataInBackground.bind(this);
    this.flatDeep = this.flatDeep.bind(this);
  }

  flatDeep(arr, d = 1) {
    return d > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val),
          []
        )
      : arr.slice();
  }
  async getDataInBackground(timestamp) {
    const { transactionData } = this.state;

    api
      .get("miner/venly/transfersList", { timestamp: timestamp })
      .then((data) => data.data.Transactions)
      .then((data) => {
        if (!_.isEmpty(data)) {
          let oldArr = [];
          oldArr.push(transactionData);
          let newArr = data.map((item) => ({
            eid: item.transactionRequest.id,
            timestampms: new Date(item.createdAt).getTime(),
            txHash: item.transactionHash,
            amount: niceNumberDecimalDisplay(
              item.transactionRequest.value / 100000000,
              8
            ),
            currency: "BTC",
            type: item.transactionRequest.type,
            destination: item.transactionRequest.to,
            status: item.transactionRequest.status,
          }));
          data.forEach((elem) => {
            newArr.push(elem);
          });
          transactionData.forEach((elem) => {
            newArr.push(elem);
          });

          const time = newArr[0].timestampms + 1;
          this.setState(
            {
              transactionData: newArr,
              totalSize: _.size(newArr),
            },
            () => {
              this.setTableData();
              // this.getDataInBackground(time);
            }
          );
        } else {
          this.setState(
            {
              transactionData: transactionData,
              totalSize: _.size(transactionData),
            },
            () => {
              this.setTableData();
            }
          );
          return;
        }
      });
  }

  copyDepositAddress() {
    let msg = "Address successfully copied!";
    toast.success(msg);
  }

  componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("remove");
    this.getRecords();
  }

  async setTableData() {
    const { sizePerPage, page, transactionData } = this.state;

    let data = [];
    if (page !== "") {
      for (let i = 0; i < page * sizePerPage; i++) {
        data.push(transactionData[i]);
      }
      data = data.slice(-sizePerPage);
    }

    const filterData = data.filter((data) =>
      data ? (data.timestampms && data.amount ? data : "") : ""
    );

    this.setState({
      recordData: filterData,
    });
  }
  async getRecords() {
    let response;
    try {
      response = await api.get("miner/venly/transfersList");

      if (!_.isEmpty(response.data.Transactions)) {
        const time = response.data.Transactions[0].timestampms + 1;
        this.setState(
          {
            transactionData: response.data.Transactions,
            totalSize: _.size(response.data.Transactions),
            tableLoading: false,
          },
          () => {
            this.setTableData();
            this.getDataInBackground(time);
          }
        );
      } else if (_.isEmpty(response.data.Transactions)) {
        this.setState({ transactionData: [], tableLoading: false });
        toast.error(response.message);
      }
    } catch (e) {
      pageProgress("force_remove");
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.setTableData();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.setTableData();
        });
        return true;
      }
    }
  };

  render() {
    const {
      renderFlag,
      tableLoading,
      recordData,
      totalSize,
      page,
      sizePerPage,
    } = this.state;
    const _this = this;

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "eid",
        text: "TXN ID",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "timestampms",
        text: "TRANSACTION DATE",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "txHash",
        text: "TRANSACTION HASH",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let smallAddress = "";
          if (row.txHash) {
            smallAddress = String(row.txHash);
            smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
              smallAddress.length - 3,
              smallAddress.length
            )}`;
          }

          return (
            <div>
              {row.txHash ? (
                <CopyToClipboard
                  text={row.txHash}
                  onCopy={() => _this.copyDepositAddress()}
                >
                  <span>
                    {smallAddress}
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2 standardColor"
                      aria-hidden="true"
                    ></i>
                  </span>
                </CopyToClipboard>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amount",
        text: "AMOUNT SENT",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>
                {Number(cell).toFixed(8)} {row.currency}
              </div>
            </div>
          );
        },
      },

      {
        headerClasses: "text-bold",
        dataField: "type",
        text: "TYPE",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row) {
          return <div>{row.type}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "destination",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row) {
          let smallAddress = "";
          if (row.destination) {
            smallAddress = String(row.destination);
            smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
              smallAddress.length - 3,
              smallAddress.length
            )}`;
          }

          return (
            <div>
              {row.destination ? (
                <CopyToClipboard
                  text={row.destination}
                  onCopy={() => _this.copyDepositAddress()}
                >
                  <span>
                    {smallAddress}
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2 standardColor"
                      aria-hidden="true"
                    ></i>
                  </span>
                </CopyToClipboard>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>{row.status}</span>;
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper mt-4">
              <div className="element-box card-inner-padding">
                <div className="row justify-content-between">
                  <h5 className="text-xl">Transactions</h5>
                  <span>
                    <Link to="/wallets">
                      <i
                        className="fa fa-arrow-left standardColorIcon mr-4"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </Link>
                  </span>
                </div>
                <div className="clearfix"></div>
                <div>
                  {tableLoading && (
                    <div className="text-center">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={recordData}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(VenlyTranscationPage);
